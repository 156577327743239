<template lang="pug">
div(class='lg:grid lg:gap-x-16' :class='{ "lg:grid-cols-2": renderDealerLocator }')
  div
    h2(class='mt-0') Installation
    ProductExtraInfoVideoList(
      v-if='data.installation.videos'
      :videos='data.installation.videos'
      section='installation'
      class='my-6'
      :grid-cols='data.installation.videos.length > 2 && !renderDealerLocator ? 4 : 2'
    )
    InlineHtml(v-if='data.installation.content' :text='data.installation.content' class='block my-6')
    ProductExtraInfoFileList(v-if='data.installation.files' :files='data.installation.files' class='my-6')

  div(v-if='renderDealerLocator' ref='dealerElement' class='mt-12 lg:border-l lg:border-l-gray-light lg:pl-16 lg:mt-0')
    h2(class='mt-0') Get It Installed
    div(class='mb-2') Don’t want to install it yourself? Search for installers in your area.
    ClientOnly
      DealerLocatorForm
    DealerLocatorDisplay(:limit-results='3' :brand='data.brand.name')
      template(#showMore)
        NuxtLink(
          :to='dealerRoute'
          class='flex items-center justify-center font-bold py-2 px-6 border rounded !no-underline'
          @click='clickShowMore'
        ) Show More
      template(#result='{ result }')
        ProductDealerLocatorDealer(:data='data' :result='result')
</template>

<script setup lang="ts">
const { $uiEvents } = useNuxtApp()
const dealerData = useDealerLocator()
const { isIntegrationEnabled } = useUtils()
const { observe } = useRunOnVisible()
const dealerElement = ref()
const { data } = defineProps<{
  data: ProductLine
}>()

onMounted(() => {
  if (dealerElement.value) observe(dealerElement.value, emitViewed, () => {})
})

const renderDealerLocator = computed(() => {
  return isIntegrationEnabled('googlePlaces') && dealerData.showDealerLocatorOnProductPage(data.brand?.name)
})

const dealerRoute = computed(() => {
  return dealerData.getDealerRoute(data.brand?.name)
})

function emitViewed() {
  $uiEvents.$emit('dealerLocatorProductViewed', {
    product: data,
  })
}

function clickShowMore(event: any) {
  $uiEvents.$emit('dealerLocatorProductLinkClicked', {
    product: data,
    linkText: event.target?.innerText,
    clickUrl: event.target?.pathname,
  })
}
</script>
