<template lang="pug">
div(class='flex mt-5 h-12')
  div(v-if='qtySelector.enabled' class='flex shrink h-full' style='min-width: 76px')
    div(class='select w-full h-full' :class='{ "border border-r-0 is-disabled": disabled }')
      select(
        v-model='qty'
        data-testId='quantitySelect'
        :disabled='disabled'
        aria-label='Quantity'
        class='w-full h-full text-center pr-[32px] !rounded-r-none !border-r-0'
      )
        option(v-for='n in 10' :key='n' :value='n') {{ n }}
  div(class='h-full w-full' :class='{ "rounded-l": !qtySelector.enabled, "border rounded-r": disabled }' @click='addToCart')
    BaseButton(
      data-cnstrc-btn='add_to_cart'
      size='MEDIUM'
      :is-disabled='disabled'
      :is-waiting='wait'
      :is-inline='true'
      class='h-full w-full'
      :class='{ "rounded-l": !qtySelector.enabled }'
    ) {{ buttonText }}
</template>

<script setup lang="ts">
const {
  disabled = false,
  qtySelector,
  isOutOfStock = false,
  isPreOrder = false,
  groupKey,
  sku = '',
} = defineProps<{
  disabled?: boolean
  qtySelector: ProductLine['qtySelector']
  isOutOfStock?: boolean
  isPreOrder?: boolean
  sku?: string // since we want the Add to Cart button to show, even when it's not 'ready' with a valid sku we just add a default b.s. value here
  groupKey?: string
}>()

const cartStore = useCartStore()
const fitmentStore = useFitmentStore()
const { $overlay, $uiEvents, $installernet, $geolocation, $extend, $amplitude } = useNuxtApp()
const log = useLogger('ADD_TO_CART')
const { isIntegrationEnabled } = useUtils()
const notifications = useNotifications()
const qty = ref(qtySelector.default)
const wait = ref(false)

// A/B experiment to hide Extend popup (UWP-13120)
const hideExtendPopup = ref(false)

onMounted(async () => {
  // A/B experiment to hide Extend popup (UWP-13120)
  if (isIntegrationEnabled('amplitude')) {
    const experimentKey = 'suppressing-extend-pop-up-on-atc'
    const variant = await $amplitude.getVariant(experimentKey)
    hideExtendPopup.value = variant?.value === 'treatment'
    $amplitude.track(experimentKey)
  }
})

const buttonText = computed(() => {
  if (isOutOfStock) return 'Out of Stock'
  if (isPreOrder) return 'Pre-Order Now'
  return 'Add to Cart'
})

async function addToCart() {
  if (disabled) return alertDrill(groupKey)

  // Double Click Protection
  if (wait.value) return

  wait.value = true

  try {
    await cartStore.addItem({
      productId: sku,
      qty: qty.value,
      fitmentData: fitmentStore.$state,
    })
  } catch (error) {
    wait.value = false
    notifications.addError('Error', 'Unable to add item to cart. Please try again later.')
    return
  }

  // Tell Segment what we just added
  $uiEvents.$emit('addToCart')

  if (isIntegrationEnabled('installernet') && isIntegrationEnabled('geolocation')) {
    const quoteId = $installernet.getQuoteIdBySku(sku)

    if (quoteId) {
      await $installernet
        .addInstallation(cartStore.lastItem?.itemId, quoteId, $geolocation.city, $geolocation.region)
        .catch((e) => {
          log.warning('installernet error', { e })
        })

      $uiEvents.$emit('installationAdded', cartStore.lastItem)
    }
  }

  wait.value = false

  // A/B experiment to hide Extend popup (UWP-13120)
  if (isIntegrationEnabled('extend') && !hideExtendPopup.value) await $extend.openModal(cartStore.lastItem)
  $overlay.open('mc')
}
</script>
