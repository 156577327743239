<template lang="pug">
DealerLocatorDisplay(v-if='renderDealerLocator' :limit-results='1' :brand='data.brand.name' :show-please-note='false')
  template(#result='{ result }')
    div(class='p-4 border-y content')
      p(class='mb-4 font-bold') Shop at your local dealer
      ProductDealerLocatorDealer(:data='data' :result='result')

  template(#empty)
    div(class='flex items-center gap-x-4 leading-tight')
      img(src='/images/pin-icon.svg' width='27' height='32' alt='Find A Dealer')

      NuxtLink(:to='dealerRoute' class='font-bold')
        span(@click='emitDealerLinkEvent') Find A Local Dealer
</template>

<script setup lang="ts">
const dealerData = useDealerLocator()

const { data } = defineProps<{
  data: ProductLine
}>()

const renderDealerLocator = computed(() => {
  return dealerData.showDealerLocatorOnProductPage(data.brand.name)
})

const dealerRoute = computed(() => {
  return dealerData.getDealerRoute(data.brand?.name)
})
</script>
