<template lang="pug">
BaseForm(v-model='groups' @submit='addToCart')
  ProductSmallGroup(
    v-for='(groupKey, index) in ocStore.groupKeys'
    :key='groupKey'
    :group-key='groupKey'
    :array-index='index'
    :qty-selector='data.qtySelector'
    :is-dealer-only='data.isDealerOnly'
  )

  div(class='space-y-2')
    BaseButton(
      v-if='!$sitewideConfig.config.nonTransactionalEnabled'
      type='submit'
      size='MEDIUM'
      :is-disabled='isDisabled'
      :is-waiting='wait'
      class='h-full w-full'
    ) Add To Cart

    StripePaymentRequestButtonProduct(
      v-if='!$sitewideConfig.config.nonTransactionalEnabled && isIntegrationEnabled("stripe") && $sitewideConfig.config.stripePaymentRequestButton && formattedSkus.length > 0'
      :sku='formattedSkus'
    )
  div(class='mt-6')
    ProductDealerLocatorSingleDisplay(:data='data')
</template>
<script setup lang="ts">
const ocStore = useOrderControlStore()
const cartStore = useCartStore()
const fitmentStore = useFitmentStore()
const notifications = useNotifications()
const { $overlay, $uiEvents, $sitewideConfig } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()
const wait = ref(false)
const { data } = defineProps<{ data: ProductLine }>()

const groups = ref({})

const formattedSkus = computed(() => {
  return Object.entries(groups.value)
    .filter(([, checked]) => checked)
    .map(([key]) => key)
})

const isDisabled = computed(() => {
  return formattedSkus.value.length === 0
})

async function addToCart(formData) {
  // nonTransactionalEnabled shouldn't matter but... just in case
  if (wait.value || $sitewideConfig.config.nonTransactionalEnabled) return
  wait.value = true

  const items = []
  Object.entries(formData).forEach(([key, value]) => {
    // Only add items that are checked
    if (value === true) {
      items.push({
        productId: key,
        qty: 1,
        fitmentData: fitmentStore.$state,
      })
    }
  })

  try {
    await cartStore.addItem(items)
  } catch (error) {
    wait.value = false
    notifications.addError('Error', 'Unable to add item to cart. Please try again later.')
    return
  }

  // Tell Segment what we just added
  $uiEvents.$emit('addGroupsToCart', items)

  wait.value = false

  $overlay.open('mc')
}
</script>
