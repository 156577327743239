<template lang="pug">
div(class='leading-none space-y-2')
  div(class='font-bold') {{ result.name }}
  div(v-if='result.isPreferred' class='flex items-center gap-x-1')
    img(src='/images/preferredDealerIcon.svg' width='16' height='16' alt='Preferred Dealer')
    span(class='text-danger-dark font-bold') Preferred Dealer

  a(
    v-if='result.phone'
    class='text-inherit block'
    :href='`tel:${result.phone}`'
    @click='$uiEvents.$emit("dealerLocatorPhoneClicked", { dealer: result })'
  ) {{ formatPhoneDash(formatPhoneE164(result.phone)) }}

  div(class='pt-4')
    NuxtLink(:to='dealerRoute' @click='clickMoreInfo(result, $event)') More Info
</template>

<script setup lang="ts">
const { $uiEvents } = useNuxtApp()
const dealerData = useDealerLocator()

const { data, result } = defineProps<{
  data: ProductLine
  result: any
}>()

const dealerRoute = computed(() => {
  return dealerData.getDealerRoute(data.brand?.name)
})

function clickMoreInfo(dealer: any, event: any) {
  $uiEvents.$emit('dealerLocatorProductDealerClicked', {
    product: data,
    dealer,
    linkText: event.target?.innerText,
    clickUrl: event.target?.pathname,
  })
  dealerData.setDealerFocus(dealer.id)
}
</script>
